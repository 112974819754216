/* Container that centers content on a black background */
.container {
  display: flex;
  flex-direction: column; /* Ensure content stacks vertically */
  justify-content: flex-start; /* Align items to the top */
  align-items: center;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
  padding: 2em; /* add some padding around the edges */
}

/* Content area with a max width */
.content {
  max-width: 800px;
  width: 100%;
  padding: 1.5em;
  flex-grow: 1; /* Allow content to grow and fill available space */
}

.content h1 {
  font-size: 1.25rem;
  font-weight: normal;
  padding-bottom: 0.25em;
}

.content h2 {
  font-size: 1rem;
  color: #959594;
  font-weight: normal;
}

.mainContent {
  padding-top: 1rem;
}

.mainContent p,
.mainContent li {
  color: #959594;
  font-size: 1rem; /* a bit smaller for body text */
  line-height: 1.5; /* comfortable line spacing */
}

.mainContent ul {
  padding-left: 0; /* Remove the default left padding */
  list-style-type: none; /* Remove the default bullets */
}

.mainContent ul li {
  padding-left: 0.1em; /* Add space after the custom dash */
}

.mainContent ul li::before {
  content: "-"; /* Add a custom dash before each item */
  margin-right: 0.4em; /* Add space after the dash */
  color: inherit; /* Match the text color */
}

.childInfo {
  padding-bottom: -1em;
}

/* Responsive tweaks for smaller screens */
@media (max-width: 768px) {
  .container {
    padding: 1em;
  }

  .content h1 {
    font-size: 20px;
  }

  .content h2 {
    font-size: 16px;
  }

  .content p,
  .content li {
    font-size: 14px;
  }
}
